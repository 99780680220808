import React, { Component, createRef } from 'react';
import _, { cloneDeep as _cloneDeep, findIndex as _findIndex, find as _find, forEach as _forEach } from 'lodash';
import ReactTooltip from 'react-tooltip';

import { Button, ButtonGroup } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import FormInput from 'components/forms/FormInput';
import Loader from 'components/layout/Loader';
import ReactDOM from 'react-dom';
import Axios from 'axios';
import EditorPage from 'cc-editor/components/EditorPage';
import EditorBlock from '../EditorBlock';
import PreviewBlock from '../PreviewBlock';
import { createTimelineBlockEntry, removeBlock, resetBlock } from '../actions/Blocks';
import { setLoginForwardUrl } from '../../../actions/LoginActions';
import {
  getCampaignsR,
  getEditionR,
  initTemplateR,
  save_ContentR,
  saveToR,
  setChangesR,
  setVariables,
} from '../../../actions/EditorActions';

const ContextModal = ({ children }) => {
  return ReactDOM.createPortal(children, document.getElementById('context-modal'));
};

class BlockContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bgColor: {},
      blockForUrl: undefined,
      showUrl: false,
      imgUrl: '',
      uploading: false,
    };
  }

  updateBackground = (bgColorID, blockId) => {
    const newBgColor = this.state.bgColor;
    newBgColor[blockId] = bgColorID;
    this.setState({ bgColor: newBgColor });
  };

  imageUpload = (id, image, imageKey, name, data, width, height, filename, thumb, copyright) => {
    const { imageUpload } = this.props;

    imageUpload(id, image, imageKey, name, data, width, height, filename, thumb, copyright, this.updateParams);
  };

  updateSpecialBlockAttributesInContainer = (id, name, value, thumb, errors, filter, url) => {
    const error = {};
    const params = [{ name, value, thumb, error, filter, url }];
    this.updateParams(id, params, errors);
    return true;
  };

  updateParam = (id, name, value, errors, name2 = '', value2 = '', page, initialUpdate, type) => {
    const error = {};
    if (errors !== null) {
      _.forEach(errors, ({ overflow, default_image }) => {
        error.overflow = overflow;
        error.default_image = default_image;
      });
    }
    let params = [{ name, value, error }];
    if (name2 !== '' && value2 !== '') {
      params = [{ name, value, error }, { name: name2, value: value2, error: errors }];
    }
    this.updateParams(id, params, errors, page, initialUpdate, type);
    return true;
  };

  updateParams = (blockId, paramList, errors, page = null, initialUpdate, type) => {
    const { blocks, updateInnerBlock, spaceConsumed, usedMedia, id } = this.props;
    const data = _cloneDeep(blocks);
    const element = _find(data, { id: blockId });
    const { params } = element;
    /*
        if (errors !== null) {
          paramList.error = element.errors;
          paramList.error.overflow = errors?.overflow;
          paramList.error.default_image = errors?.default_image;
        }
    */

    _forEach(paramList, ({ name, value, error, filter, url }) => {
      const paramIndex = _findIndex(params, ['name', name]);
      const paramValue = { name, value, errors: error, filter, url };
      if (paramIndex >= 0) {
        // parameter was found
        params[paramIndex] = paramValue;
      } else {
        if (type === 'creatomate') {
          this.addAndSortParams(params, paramValue);
        } else {
          // parameter was not found, append the parameter with its value
          params.push(paramValue);
        }

      }
    });


    if (initialUpdate) {
      // that is the initial updater in loops via prevState
      updateInnerBlock(id, data, spaceConsumed, usedMedia, page, null, blockId, initialUpdate, paramList, type);
    } else {
      // that is the default innerblock update
      updateInnerBlock(id, data, spaceConsumed, usedMedia, page, null, blockId);
    }
  };

  addAndSortParams = (params, paramValue) => {
    params.push(paramValue);
    params.sort((a, b) => {
      const numA = parseInt(a.name.match(/\d+$/)[0]);
      const numB = parseInt(b.name.match(/\d+$/)[0]);
      return numA - numB;
    });
  }


  removeBlocksDefault = (sourceRasterId, sourceSpace) => {
    const { blocks, spaceConsumed, usedMedia, updateInnerBlock } = this.props;
    const changes = removeBlock(
      0,
      [{ blocks, space_consumed: spaceConsumed }],
      null,
      sourceRasterId,
      sourceSpace,
      true,
      usedMedia
    );
    updateInnerBlock(
      this.props.id,
      changes.contentPages[0].blocks,
      changes.contentPages[0].space_consumed,
      changes.usedMedia
    );
    // remove child of block inside container
    this.removeBlocksChildAuto(sourceRasterId);

    // remove colorSetting of block inside container
    this.props.deleteSelectedColor(sourceRasterId);
  };

  removeBlocksChildAuto = sourceRasterId => {
    const { blocks, spaceConsumed, usedMedia, updateInnerBlock } = this.props;
    const indexOfParent = _.findIndex(blocks, ['id', sourceRasterId]); // needed for delete child
    const parent = blocks[indexOfParent]?.block_combination_parent; // needed for delete child
    if (parent !== undefined && parent !== '') {
      _forEach(blocks, (block, iB) => {
        const blockCombinationChild = block.block_combination_child.split(',');
        _forEach(blockCombinationChild, (blockCC, iBC) => {
          if (blockCC === parent) {
            this.setDelay(block.id, block.page_space_needed);
          }
        });
      });
    }
  };

  setDelay = (blockId, blockPageSpaceNeeded) => {
    const timeOut = window.setTimeout(() => {
      this.removeBlocksChild(blockId, blockPageSpaceNeeded);
    }, 100);
  };

  removeBlocksChild = (sourceRasterId, sourceSpace) => {
    const { blocks, spaceConsumed, usedMedia, updateInnerBlock } = this.props;
    this.props.deleteSelectedColor(sourceRasterId);
    const changes = removeBlock(
      0,
      [{ blocks, space_consumed: spaceConsumed }],
      null,
      sourceRasterId,
      sourceSpace,
      true,
      usedMedia
    );
    updateInnerBlock(
      this.props.id,
      changes.contentPages[0].blocks,
      changes.contentPages[0].space_consumed,
      changes.usedMedia
    );
  };

  deleteBlock = e => {
    const { blocks, spaceConsumed, usedMedia, updateInnerBlock } = this.props;
    const { target } = e;
    const sourceRasterId = target.getAttribute('data-block');
    const {
      intl: { messages },
    } = this.props;
    const sourceSpace = parseInt(target.getAttribute('data-space'));

    /* check if block is parent of a child to set warning before delete */
    const indexOfParent = _.findIndex(blocks, ['id', sourceRasterId]); // needed for delete child
    const parent = blocks[indexOfParent]?.block_combination_parent; // needed for delete child
    let warning = messages.editor.delete_selected_content_block;
    if (parent !== undefined && parent !== '') {
      warning = messages.editor.delete_selected_content_block + ' ' + messages.editor.delete_children_of_parents;
    }

   const options = {
      title: messages.editor.delete_content + '?',
      message: warning,
      buttons: [
        {
          label: messages.editor.yes_delete,
          onClick: () => {
            this.removeBlocksDefault(sourceRasterId, sourceSpace);
          },
        },
        {
          label: messages.editor.rather_not,
          onClick: () => {},
        },
      ],
      childrenElement: () => <div />,
      closeOnEscape: true,
      closeOnClickOutside: true,
    };

    confirmAlert(options);
  };

  copyBlock = (e, id) => {
    const { copyBlock, data, copiedBlock, pasteData } = this.props;
    copyBlock(e, 'inner');
    this.forceUpdate(() => {});
  };

  pasteBlock = e => {
    const { pasteBlock } = this.props;
    pasteBlock(e, 'inner');
    this.forceUpdate(() => {});
  };

  moveBlock = e => {
    const { blocks, spaceConsumed, usedMedia, updateInnerBlock } = this.props;
    const { target } = e;
    const sourceIndex = target.getAttribute('data-index');
    const targetIndex = target.getAttribute('data-target');
    const changedBlocks = _cloneDeep(blocks);
    changedBlocks[targetIndex] = _cloneDeep(blocks[sourceIndex]);
    changedBlocks[sourceIndex] = _cloneDeep(blocks[targetIndex]);
    updateInnerBlock(this.props.id, changedBlocks, spaceConsumed, usedMedia);
  };

  resBlock = e => {
    const { blocks, spaceConsumed, usedMedia, updateInnerBlock, templateBlocks } = this.props;
    const { target } = e;
    const {
      intl: { messages },
    } = this.props;
    const options = {
      title: messages.editor.reset_content + '?',
      message: messages.editor.reset_content_block_to_root,
      buttons: [
        {
          label: messages.editor.yes_reset,
          onClick: () => {
            const rasterId = target.getAttribute('data-block');
            const templateId = target.getAttribute('data-template');
            const changes = resetBlock(rasterId, templateId, [{ blocks }], 0, templateBlocks, usedMedia);
            updateInnerBlock(this.props.id, changes.contentPages[0].blocks, spaceConsumed, changes.usedMedia);

            this.forceUpdate();
          },
        },
        {
          label: messages.editor.rather_not,
          onClick: () => {},
        },
      ],
      childrenElement: () => <div />,
      closeOnEscape: true,
      closeOnClickOutside: true,
    };

    confirmAlert(options);
  };

  getSortedBlocksCopy = (cRow, cCol, blocks) => {
    let col = 1;
    const cRowInit = cRow;
    const sortedBlock = [];
    for (let i = 0; i < blocks.length; i++) {
      if (blocks[i].page_space_needed <= cRow) {
        for (let a = 0; a < sortedBlock.length; a++) {
          if (sortedBlock.length > 0 && sortedBlock[a].col === col) {
            sortedBlock[a].R = cRow - blocks[i].page_space_needed;
          }
        }
        cRow -= blocks[i].page_space_needed;
      } else {
        col += 1;
        cRow = cRowInit;
        cRow -= blocks[i].page_space_needed;
      }
      sortedBlock.push({ id: blocks[i].id, col, R: cRow, disabled: true });
    }
    return sortedBlock;
  };

  getMaxFreeSpaceInContainer = (_sortedBlocks, spaceNeeded) => {
    let freeRow;
    let maxFilledCol; // erhalte die höchste reihe in der sich ein block befindet
    let sortedBlocksNew = [];
    _forEach(_sortedBlocks, (sBlock, i) => {
      maxFilledCol = sBlock.col;
    });
    // more than 1 col is free
    if (maxFilledCol + 1 < this.props.data.container_cols && this.props.data.container_cols >= spaceNeeded) {
      _forEach(_sortedBlocks, (sBlock, i) => {
        sBlock.disabled = false;
      });
      sortedBlocksNew = _sortedBlocks;
    }

    // only 1 col is free
    if (maxFilledCol < this.props.data.container_cols && this.props.data.container_cols >= spaceNeeded) {
      _forEach(_sortedBlocks, (sBlock, i) => {
        sBlock.disabled = true;
      });
      for (let i = 1; i <= maxFilledCol; i++) {
        const filteredBlocks = _.filter(_sortedBlocks, x => x.col === i);
        filteredBlocks[filteredBlocks.length - 1].disabled = false;
        sortedBlocksNew = sortedBlocksNew.concat(filteredBlocks);
      }
    }
    // only a part of col is free
    // add cols are full -> first tests seemed to work
    // before add -> if(maxFilledCol === this.props.data.container_cols && this.props.data.container_cols >= spaceNeeded){
    if (maxFilledCol === this.props.data.container_cols) {
      _forEach(_sortedBlocks, (sBlock, i) => {
        if (sBlock.R < spaceNeeded) {
          sBlock.disabled = true;
        } else {
          sBlock.disabled = false;
        }
        sortedBlocksNew = _sortedBlocks;
      });
    }
    return sortedBlocksNew;
  };

  handleIEClose = () => {
    this.setState({ showUrl: false });
  };

  getImageUrlSettings = block => {
    this.setState({ blockForUrl: block }, () => {
      if (block.params[0]?.url !== undefined && block.params[0].url !== null) {
        this.setState({ imgUrl: block?.params[0]?.url }, () => {
          this.setState({ showUrl: true });
        });
      } else {
        this.setState({ imgUrl: '' }, () => {
          this.setState({ showUrl: true });
        });
      }
    });
  };

  saveUrl = () => {
    const id = this.state.blockForUrl?.id;
    let value = '';
    let name = '';
    let errors = null;
    let thumb = '';
    let filter = '';

    if (this.state.blockForUrl?.params.length > 0) {
      value = this.state.blockForUrl?.params[0]?.value;
      name = this.state.blockForUrl?.params[0]?.name;
      errors = this.state.blockForUrl?.params[0]?.errors;
      thumb = this.state.blockForUrl?.params[0]?.thumb;
      filter = this.state.blockForUrl?.params[0]?.filter;
    } else {
      const { src, dataName } = this.extractImgAttributes(this.state.blockForUrl.template);
      value = src;
      name = dataName;
      errors = null;
      thumb = '';
      filter = '';
    }

    this.updateSpecialBlockAttributesInContainer(id, name, value, thumb, errors, filter, this.state.imgUrl);
    this.setState({ showUrl: false });
  };

  extractImgAttributes = element => {
    const regex = /<img.*?src="(.*?)".*?data-name="(.*?)"/;
    const match = element.match(regex);
    if (match) {
      const src = match[1];
      const dataName = match[2];
      return { src, dataName };
    }
    return null;
  };

  uploadFiles = e => {
    const {
      intl: { messages },
    } = this.props;
    this.setState({ uploading: true, progress: 0 });
    const formData = new FormData();
    _forEach(e.target.files, (file, i) => {
      formData.append('file' + i, file);
    });

    // formData.append("json", JSON.stringify(mediaData));

    this.uploadPDF(formData)
      .then(response => {
        this.setState({ uploading: false, imgUrl: response.data }, () => {
          const options = {
            customUI: ({ onClose }) => {
              return (
                <div className="react-confirm-alert-body">
                  <h1>{messages.upload_pdf_title}</h1>
                  <p>{messages.upload_pdf_status_done}</p>
                  <div className="react-confirm-alert-button-group" style={{ width: '100%', margin: '0 auto' }}>
                    <Button
                      style={{ margin: '0 auto', marginTop: '20px', width: '100px' }}
                      bsStyle="info"
                      onClick={onClose}
                    >
                      OK
                    </Button>
                  </div>
                </div>
              );
            },
            childrenElement: () => <div />,
            closeOnEscape: true,
            closeOnClickOutside: true,
          };
          confirmAlert(options);
        });
      })
      .catch(() => {
        this.setState({ uploading: false }, () => {
          const options = {
            customUI: ({ onClose }) => {
              return (
                <div className="react-confirm-alert-body">
                  <h1>{messages.upload_pdf_title}</h1>
                  <p>{messages.upload_pdf_status_error}</p>
                  <div className="react-confirm-alert-button-group" style={{ width: '100%', margin: '0 auto' }}>
                    <Button
                      style={{ margin: '0 auto', marginTop: '20px', width: '100px' }}
                      bsStyle="info"
                      onClick={onClose}
                    >
                      OK
                    </Button>
                  </div>
                </div>
              );
            },
            childrenElement: () => <div />,
            closeOnEscape: true,
            closeOnClickOutside: true,
          };
          confirmAlert(options);
        });
      });
  }; // End uploadFiles

  // contact to API
  uploadPDF = formData => {
    return Axios.post('/api/editions/uploads/pdfs.json', formData);
  };

  render() {
    const { bgColor } = this.state;
    const {
      blocks,
      type,
      appIntl,
      pageZoom,
      spaceConsumed,
      spaceTotal,
      settings,
      index,
      id,
      usedMedia,
      containerSelect,
      rasterV,
      pasteData,
      copiedBlock,
      attributes,
      showKeyboard,
      data,
      intl: { messages },
      isSubmission,
      wlAdminUser,
      colorSettings,
    } = this.props;
    const zoomEqualizer = 1 / parseFloat(pageZoom);
    const styleOptions = {
      transform: `scale(${zoomEqualizer})`,
    };
    let pasteButton = '';
    const pasteDisable = false;

    let _sortedBlocks;
    let _blocks;

    if (copiedBlock !== null && copiedBlock !== undefined) {
      _sortedBlocks = this.getSortedBlocksCopy(data.container_space, data.container_cols, data.blocks);
      _blocks = this.getMaxFreeSpaceInContainer(_sortedBlocks, copiedBlock[0].page_space_needed);
    }

    return (
      <div className={`blockContainer ${attributes.className}`} onClick={containerSelect}>
        {!blocks
          ? null
          : blocks.map((block, i) => {
              const sectionClassPre = block.template ? block.template.match(/(?:data-section=\").*?(?=\s*\")/gm) : [''];
              const sectionClass = sectionClassPre ? ' ' + sectionClassPre[0].replace('data-section="', '') : '';
              const {
                intl: { messages },
              } = this.props;
              let blockUpDisable = true;
              let blockDownDisable = true;
              const blockDeleteDisable = false;
              const blockEditDisable = false;
              const blockCopyDisable = false;
              if (pasteData && type === 'active') {
                pasteButton = (
                  <Button
                    data-tip={messages.editor.paste_content_after}
                    disabled={
                      this.props.blockPasteDisable === false
                        ? _blocks
                          ? _blocks[i]?.disabled
                          : true
                        : this.props.blockPasteDisable
                    }
                    data-for="blockinfo"
                    data-block={block.id}
                    data-parent={this.props.id}
                    onClick={this.pasteBlock}
                  >
                    <i className="fas fa-sign-in fa-rotate-90" data-block={block.id} data-parent={this.props.id} />
                  </Button>
                );
              }
              const blockHeight = rasterV * block.page_space_needed;
              if (type !== 'active') {
              }
              const innerBlockHeight = {
                height: blockHeight + 'px',
              };
              if (i > 0) {
                blockUpDisable = false;
              }
              if (i < blocks.length - 1) {
                blockDownDisable = false;
              }
              const sectionRef = createRef();
              let error = '';
              let errorText = '';
              if (block.errors.overflow) {
                error = 'overflow';
                errorText = messages.editor.text_overflow;
              }
              if (block.errors.default_image) {
                error += ' default-image';
                errorText += messages.editor.initial_image_available;
              }
              let inlineClass = '';
              if (block.inline_block) {
                inlineClass = ' inline-block col-section';
              }

              const heightClass = ' height_' + block.page_space_needed;

              let sectionColor = '';
              if (this.props.getColorSettings) {
                sectionColor = this.props.getColorSettings(block?.id);
              }

            let sectionFontSize = ' ';
            sectionFontSize = ' ' + (this.props.fontSizeSettings[(block?.id)] ?? ' ') + ' ';

              return (
                <section
                  ref={sectionRef}
                  style={innerBlockHeight}
                  data-tip={errorText}
                  data-for="errorinfo"
                  className={heightClass + inlineClass + sectionClass + sectionColor + sectionFontSize}
                >
                  {type === 'active' ? (
                    <EditorBlock
                      appIntl={appIntl}
                      data={block}
                      settings={settings}
                      updateParam={this.updateParam}
                      zoomEqualizer={zoomEqualizer}
                      index={index + '.' + id + '.' + i}
                      pageZoom={pageZoom}
                      imageUpload={this.imageUpload}
                      usedMedia={usedMedia}
                      bgColor={bgColor[block.id]}
                      key={id + '.' + i}
                      showKeyboard={showKeyboard}
                      resizeBlock={this.props.resizeBlock}
                      blockBiggerDisable={this.props.blockBiggerDisable}
                      autoResizeSupported={false}
                      getDeviceType={this.props.getDeviceType}
                      currentPage={this.props.currentPage}
                      setSelectedColor={this.props.setSelectedColor}
                      colorSettings={colorSettings}
                      setFontSizeSettings={this.props.setFontSizeSettings}
                      fontSizeSettings={this.props.fontSizeSettings}
                      getColorSettings={this.props.getColorSettings}
                      deleteSelectedColor={this.props.deleteSelectedColor}
                      blockContainerId={id}
                      wlAdminUser={wlAdminUser}
                      isSubmission={isSubmission}
                      uploadError={this.props.uploadError}
                    />
                  ) : (
                    <PreviewBlock
                      appIntl={appIntl}
                      data={block}
                      index={index + '.' + id + '.' + i}
                      key={id + '.' + i}
                      bgColor={bgColor}
                      setFontSizeSettings={this.props.setFontSizeSettings}
                      fontSizeSettings={this.props.fontSizeSettings}
                      updateParam={this.updateParam}
                      currentPage={this.props.currentPage}
                      setSelectedColor={this.props.setSelectedColor}
                      blockContainerId={id}
                      usedMedia={usedMedia}
                    />
                  )}
                  {type === 'active' ? (
                    <div className="block-options" style={styleOptions}>
                      <ButtonGroup vertical bsSize="small">
                        <Button
                          data-tip={messages.editor.up}
                          data-for="blockinnerinfo"
                          disabled={blockUpDisable}
                          data-block={block.id}
                          data-index={i}
                          data-target={i - 1}
                          onClick={this.moveBlock}
                        >
                          <i className="far fa-angle-up" data-block={block.id} data-index={i} data-target={i - 1} />
                        </Button>
                        <Button className="toggle-edit" disabled={blockEditDisable} data-block={block.id}>
                          <i className="fas fa-edit" data-block={block.id} />
                          <ButtonGroup className="edit-block" bsSize="small" vertical>
                            <Button
                              data-tip={messages.editor.reset_block}
                              data-for="blockinnerinfo"
                              data-block={block.id}
                              data-template={block.template_id}
                              onClick={this.resBlock}
                            >
                              <i className="fas fa-retweet" data-block={block.id} data-template={block.template_id} />
                            </Button>
                            {block.template && block.template.includes('<img') /* && block.params.length > 0 */ && (
                              <>
                                <Button
                                  data-tip={messages.url_settings}
                                  data-for="blockinfo"
                                  data-block={block.id}
                                  data-template={block.template_id}
                                  onClick={() => this.getImageUrlSettings(block)}
                                >
                                  <i className="fad fa-link" data-block={block.id} data-template={block.template_id} />
                                </Button>
                              </>
                            )}
                            <Button
                              data-tip={messages.editor.copy_block}
                              data-for="blockinnerinfo"
                              disabled={blockCopyDisable}
                              data-block={block.id}
                              data-parent={this.props.id}
                              onClick={e => this.copyBlock(e, block.id)}
                            >
                              <i className="fas fa-clone" data-block={block.id} data-parent={this.props.id} />
                            </Button>
                            {block.template && block.template.includes('data-background=') && (
                              <Button data-block={block.id} data-parent={this.props.id} className="toggle_bg">
                                <i className="fas fa-fill-drip" data-block={block.id} data-parent={this.props.id} />
                                <ButtonGroup className="edit-color" bsSize="small" vertical>
                                  {this.props.settings.target &&
                                    this.props.settings.target.back_colors &&
                                    this.props.settings.target.back_colors.map(bgColor => {
                                      const getBackgroundAttr = block.template.match(
                                        /(?:data-background=\").*?(?=\s*\")/gm
                                      );
                                      if (
                                        getBackgroundAttr[0].includes(bgColor.label[0].name) ||
                                        getBackgroundAttr[0].includes('true')
                                      ) {
                                        return (
                                          <Button
                                            data-tip={messages.editor.change_backgroundcolor}
                                            data-for="blockinfo"
                                            data-block={bgColor.id}
                                            data-parent={block.id}
                                            onClick={() => this.updateBackground(bgColor.label[0].name, block.id)}
                                            style={{ padding: 4 }}
                                          >
                                            <p
                                              style={{
                                                width: 20,
                                                height: 20,
                                                padding: 0,
                                                margin: 0,
                                                background: bgColor.color,
                                              }}
                                            />
                                          </Button>
                                        );
                                      }
                                      return null;
                                    })}
                                  {this.props.settings.back_colors &&
                                    this.props.settings.back_colors.map(bgColor => {
                                      const getBackgroundAttr = block.template.match(
                                        /(?:data-background=\").*?(?=\s*\")/gm
                                      );
                                      if (
                                        getBackgroundAttr[0].includes(bgColor.label[0].name) ||
                                        getBackgroundAttr[0].includes('true')
                                      ) {
                                        return (
                                          <Button
                                            data-tip={messages.editor.change_backgroundcolor}
                                            data-for="blockinfo"
                                            data-block={bgColor.id}
                                            data-parent={this.props.id}
                                            onClick={() => this.updateBackground(bgColor.label[0].name, block.id)}
                                            style={{ padding: 4 }}
                                          >
                                            <p
                                              style={{
                                                width: 20,
                                                height: 20,
                                                padding: 0,
                                                margin: 0,
                                                background: bgColor.color,
                                              }}
                                            />
                                          </Button>
                                        );
                                      }
                                      return null;
                                    })}
                                </ButtonGroup>
                              </Button>
                            )}
                            {/* biggerButton}
                        {smallerButton */}
                            <Button
                              data-tip={messages.editor.delete_block}
                              data-for="blockinnerinfo"
                              disabled={blockDeleteDisable}
                              data-block={block.id}
                              data-space={block.page_space_needed}
                              onClick={this.deleteBlock}
                            >
                              <i className="fas fa-trash" data-block={block.id} data-space={block.page_space_needed} />
                            </Button>
                          </ButtonGroup>
                        </Button>
                        {pasteButton}
                        <Button
                          data-tip={messages.editor.down}
                          data-for="blockinnerinfo"
                          disabled={blockDownDisable}
                          data-block={block.id}
                          data-index={i}
                          data-target={i + 1}
                          onClick={this.moveBlock}
                        >
                          <i className="far fa-angle-down" data-block={block.id} data-index={i} data-target={i + 1} />
                        </Button>
                      </ButtonGroup>
                      <ReactTooltip id="blockinnerinfo" place="left" effect="solid" />
                    </div>
                  ) : null}
                </section>
              );
            })}
        {this.state.showUrl && (
          <ContextModal>
            <div id="contextModalWrap">
              <div id="contextModalStage" className="row">
                <h1>{messages.url_settings}</h1>
                <FormInput
                  type="text"
                  label="Url"
                  name="url"
                  onChange={(name, value) => {
                    this.setState({ imgUrl: value });
                  }}
                  value={this.state.imgUrl}
                />
                <div className="uploadPDF">
                  <label htmlFor="file-input" style={{ cursor: 'pointer' }}>
                    <p>
                      <span style={{ fontSize: '15px' }}>
                        + <i className="far fa-file-pdf" /> {messages.upload_pdf}
                      </span>
                    </p>
                  </label>
                  <input
                    style={{ display: 'none' }}
                    id="file-input"
                    type="file"
                    accept="application/pdf"
                    onChange={e => {
                      this.uploadFiles(e);
                    }}
                  />
                  {this.state.uploading ? <Loader /> : <></>}
                </div>
                <Button
                  bsStyle="success"
                  bsSize="large"
                  style={{ position: 'fixed', left: '8vw', top: '85%', zIndex: '100' }}
                  onClick={() => {
                    this.saveUrl();
                  }}
                >
                  {messages.editor.use_filter}
                </Button>
                <Button bsStyle="danger" bsSize="large" onClick={this.handleIEClose} className="cancelIE">
                  {messages.editor.abort}
                </Button>
              </div>
            </div>
          </ContextModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.login.user,
    appIntl: state.intl,
    editionRV: state.editor.editionRV,
    pendingR: state.editor.pendingR,
    savingR: state.editor.savingR,
    unsavedR: state.editor.unsavedR,
    linkDataR: state.editor.linkDataR,
    campaignOptionsR: state.editor.campaignOptionsR,
    forwardingR: state.editor.forwardingR,
    previewingR: state.editor.previewingR,
    exportingR: state.editor.exportingR,
    printingR: state.editor.printingR,
    printx: state.editor.printx,
    changesR: state.editor.changesR,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setLoginForwardUrl: forwardUrl => {
      dispatch(setLoginForwardUrl(forwardUrl));
    },
    getEditionR: data => {
      dispatch(getEditionR(data));
    },
    save_ContentR: (data, datab) => {
      dispatch(save_ContentR(data, datab));
    },
    setVariables: data => {
      dispatch(setVariables(data));
    },
    saveToR: data => {
      dispatch(saveToR(data));
    },
    initTemplateR: (data, datab) => {
      dispatch(initTemplateR(data, datab));
    },
    getCampaignsR: () => {
      dispatch(getCampaignsR());
    },
    setChangesR: data => {
      dispatch(setChangesR(data));
    },
  };
};

export default injectIntl(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(BlockContainer)
  )
);
